import { Injectable } from '@angular/core';
import { ClipboardServiceInterface } from './clipboard-service.interface';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService implements ClipboardServiceInterface {
  private tempTextArea: HTMLTextAreaElement | undefined;

  public copyFromContent(content: string): boolean {
    const container: HTMLElement = document.body;
    if (this.tempTextArea && !container.contains(this.tempTextArea)) {
      this.destroy();
    }
    if (!this.tempTextArea) {
      this.tempTextArea = this.createTempTextArea();
      try {
        container.appendChild(this.tempTextArea);
      } catch (error) {
        throw new Error('Container should be a Dom element');
      }
    }
    this.tempTextArea.value = content;
    const toReturn = this.copyFromInputElement();
    this.destroy();
    return toReturn;
  }

  private createTempTextArea(): HTMLTextAreaElement {
    const isRTL = document.documentElement.getAttribute('dir') === 'rtl';
    let ta: HTMLTextAreaElement;
    ta = document.createElement('textarea');
    ta.style.fontSize = '12pt';
    ta.style.border = '0';
    ta.style.padding = '0';
    ta.style.margin = '0';
    ta.style.position = 'absolute';
    ta.style[isRTL ? 'right' : 'left'] = '-9999px';
    const yPosition = window.pageYOffset || document.documentElement.scrollTop;
    ta.style.top = yPosition + 'px';
    ta.setAttribute('readonly', '');
    return ta;
  }

  private destroy(): void {
    const container: HTMLElement = document.body;
    if (this.tempTextArea) {
      container.removeChild(this.tempTextArea);
      this.tempTextArea = undefined;
    }
  }

  private copyFromInputElement(): boolean {
    try {
      this.tempTextArea.select();
      this.tempTextArea.setSelectionRange(0, this.tempTextArea.value.length);
      const re = document.execCommand('copy');
      this.tempTextArea.focus();
      window.getSelection().removeAllRanges();
      return re && this.isCopySuccessInIE11();
    } catch (error) {
      return false;
    }
  }

  private isCopySuccessInIE11() {
    const clipboardData = (window as any).clipboardData;
    if (clipboardData && clipboardData.getData) {
      if (!clipboardData.getData('Text')) {
        return false;
      }
    }
    return true;
  }
}
